<template>
  <div class="kemu-page">
    <div class="mobile-page">
      <view class="tabbar">
        <view :class="['tab', tabIndex === 1?'active':'']">
          科一
          <view class="line" v-if="tabIndex === 1"></view>
        </view>
        <view :class="['tab', tabIndex === 4?'active':'']">
          科四
          <view class="line" v-if="tabIndex === 4"></view>
        </view>
      </view>
      <view class="row">
        <view class="block" @click="goDetail(1)">
          <view class="flex h vc">
            <text class="title">今日错题</text>
            <img src="@/static/mine/cuoti_jiantou.png" style="width: 4px;height: 9px;">
          </view>
          <text class="count">{{ collectTodayCount }}</text>
        </view>
        <view class="block" @click="goDetail(0)">
          <view class="flex h vc">
            <text class="title">全部错题</text>
            <img src="@/static/mine/cuoti_jiantou.png" style="width: 4px;height: 9px;">
          </view>
          <text class="count">{{ collectTotalCount }}</text>
        </view>
      </view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  data () {
    return {
      tabIndex: 1,
      kemu: 1
    }
  },
  computed: {
    ...mapGetters('question', ['kemu1', 'kemu4']),
    collectTodayCount () {
      if (this.tabIndex === 1) {
        return this.kemu1.wrongTodayCount
      } else {
        return this.kemu4.wrongTodayCount
      }
    },
    collectTotalCount () {
      if (this.tabIndex === 1) {
        return this.kemu1.wrongTotalCount
      } else {
        return this.kemu4.wrongTotalCount
      }
    }
  },
  created () {
    this.kemu = this.$route.query.kemu
    this.tabIndex = parseInt(this.kemu)
  },
  methods: {
    async goDetail (today) {
      if (today) {
        this.$router.push({
          path: 'kaochang_enter',
          query: {
            kemu: this.kemu,
            type: 'wrong',
            today: 1
          }
        })
      } else {
        this.$router.push({
          path: 'kaochang_enter',
          query: {
            kemu: this.kemu,
            type: 'wrong',
            today: 0
          }
        })
      }
    },
    select (i) {
      this.tabIndex = i
    }
  }
}
</script>

<style lang="less" scoped>
.tabbar {
  height: 50px;
  display: flex;

  .tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    font-size: 16px;
    font-weight: bold;
    color: #999;

    .line {
      background-color: #0D94FF;
      width: 32px;
      height: 3px;
      border-radius: 6px;
      position: absolute;
      bottom: 0;
    }

    &.active {
      color: #000;
    }
  }
}

.row {
  display: flex;
  margin-top: 20px;

  .block {
    flex: 1;
    background-color: #f8f8f8;
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    border-radius: 15px;
    cursor: pointer;
    &:first-child {
      margin-right: 0;
    }
  }

  .title {
    margin-right: 10px;
    color: rgba(138, 138, 138, 1);
    font-size: 18px;
  }

  .count {
    color: rgba(0, 0, 0, 1);
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;
  }
}
</style>
