<template>
  <div class="kemu-page">
    <div class="mobile-page">
      <view class="big_icon_row">
        <view class="big_icon item1" @click="goTo('shunxu')">
          <view class="big_icon_quan">
            <view class="icon">
              <view class="label">顺序练习</view>
              <view class="sub_label">扎实基础</view>
            </view>
          </view>
        </view>
        <view class="big_icon item2">
          <view class="big_icon_quan" @click="goTo('moni')">
            <view class="icon">
              <view class="label">模拟考试</view>
              <view class="sub_label">快速冲刺</view>
            </view>
          </view>
        </view>
        <view class="big_icon item3 miji" v-if="kemu===1">
          <view class="big_icon_quan" @click="goTo('miji')">
            <view class="icon">
              <view class="label">驾考秘籍</view>
              <view class="sub_label">可打印</view>
            </view>
          </view>
        </view>
        <view class="big_icon item3 jinji" v-if="kemu===5">
          <view class="big_icon_quan" @click="goTo('jinjijiuhu')">
            <view class="icon">
              <view class="label">紧急救护题</view>
              <view class="sub_label">必学</view>
            </view>
          </view>
        </view>
      </view>
      <view class="small_icon_row">
        <view class="small_icon" @click="goTo('jingxuan')" v-if="kemu===1">
          <view class="icon item1"></view>
          <view class="label">精选500题</view>
        </view>
        <view class="small_icon" @click="goTo('suji')">
          <view class="icon item3"></view>
          <view class="label">速记口诀</view>
        </view>
        <view class="small_icon" @click="goTo('kaodian')">
          <view class="icon item5"></view>
          <view class="label">考点练习</view>
        </view>
        <view class="small_icon" @click="goTo('location_question')">
          <view class="icon item7"></view>
          <view class="label">地方题库</view>
        </view>
      </view>
      <DataView :kemu="1"></DataView>
      <VideoLessonView></VideoLessonView>
      <img src="https://down.qwdata.vip/app_icon/1702746527791" mode="widthFix" class="monikaochang"
             @click="goTo('mijuan')">
      <img src="https://down.qwdata.vip/app_icon/1702746533199" mode="widthFix" class="monikaochang"
             @click="goTo('kaochang_moni_enter')">
    </div>
    <div class="right-page">
      <PDFView v-if="pdfId" :course-id="pdfId"></PDFView>
    </div>
  </div>
</template>
<script>
import moment from 'moment/moment'
import { mapGetters } from 'vuex'
import VideoLessonView from '@/components/VideoLessonView.vue'
import PDFView from '@/components/PDFView.vue'
import DataView from '@/components/DataView.vue'

export default {
  components: { DataView, PDFView, VideoLessonView },
  data () {
    return {
      carType: 1,
      kemu: 1,
      pdfId: null
    }
  },
  computed: {
  },
  methods: {
    dateFormat (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    goTo (type, title) {
      console.log('goTo', type)
      if (type === 'mijuan') {
        this.$router.push({
          path: 'mijuan',
          query: {
            kemu: 1
          }
        })
      } else if (type === 'location_question') {
        this.$router.push({
          path: 'difangti',
          query: {
            kemu: 1
          }
        })
      } else if (type === 'suji') {
        this.$router.push({
          path: 'koujue',
          query: {
            kemu: 1
          }
        })
      } else if (type === 'kaodian') {
        this.$router.push({
          path: 'kaodian',
          query: {
            kemu: 1
          }
        })
      } else if (type === 'kaochang_moni_enter') {
        this.$router.push({
          path: 'kaochang_enter',
          query: {
            kemu: 1
          }
        })
      } else if (type === 'jingxuan') {
        this.$router.push({
          path: 'kaochang_enter',
          query: {
            kemu: 1,
            type: 'jingxuan'
          }
        })
      } else if (type === 'miji') {
        this.pdfId = 'C000008'
      } else if (type === 'moni') {
        this.$router.push({
          path: 'kaochang_enter',
          query: {
            kemu: 1
          }
        })
      } else if (type === 'shunxu') {
        this.$router.push({
          path: 'kaochang_enter',
          query: {
            kemu: 1,
            type: 'shunxu'
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.mobile-page {
  background-color: #f6f6f6;
}
.big_icon_row {
  display: flex;
  padding: 0 20px;

  .big_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px 10px;
    border-radius: 14px;

    &:hover {
      background-color: #f5f5f5;
    }

    &.item1 {
      .big_icon_quan {
        border: 10px solid #e9f5ff;
      }

      .icon {
        background-image: linear-gradient(148deg, #28b2fd 0%, #0588fd 100%);
      }
    }

    &.item2 {
      flex: 1;

      .big_icon_quan {
        border: 10px solid #dffcf8;
      }

      .icon {
        background-image: linear-gradient(148deg, #36E2BB 0%, #10D0B1 100%);
      }
    }

    &.item3 {
      &.miji {
        .sub_label {
          color: rgba(255, 255, 255, 0.85);
          background: rgba(0, 0, 0, 0.23);
          border-radius: 10px;
          padding: 5px 20px;
        }
      }

      &.jinji {
        .sub_label {
          border-radius: 10px;
          padding: 5px 30px;
          color: #f9d9be;
          background: rgba(32, 28, 27, 0.3);
        }
      }

      .big_icon_quan {
        border: 10px solid #fff7f3;
      }

      .icon {
        background-image: linear-gradient(148deg, #FF8D4F 0%, #FF8A4E 100%);
      }
    }

    .big_icon_quan {
      width: 113px;
      height: 113px;
      border-radius: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 10px solid #007aff;
    }

    .icon {
      width: 110px;
      height: 110px;
      border-radius: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;

      .label {
        font-weight: bold;
      }

      .sub_label {
        font-size: 13px;
        margin-top: 8px;
        opacity: 0.9;
      }
    }
  }
}

.small_icon_row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding: 0 20px;

  .small_icon {
    width: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 14px;
    &:hover {
      background-color: #f5f5f5;
    }
    .icon {
      width: 60px;
      height: 60px;
      background-image: url("https://down.qwdata.vip/app_icon/home/500%402x.png");
      background-size: cover;

      &.item1 {
        background-image: url("https://down.qwdata.vip/app_icon/home/500%402x.png");
      }

      &.item2 {
        background-image: url("https://down.qwdata.vip/app_icon/home/kaoqian%402x.png");
      }

      &.item3 {
        background-image: url("https://down.qwdata.vip/app_icon/home/suji%402x.png");
      }

      &.item4 {
        background-image: url("https://down.qwdata.vip/app_icon/home/kaochang_enter%402x.png");
      }

      &.item5 {
        background-image: url("https://down.qwdata.vip/app_icon/home/lianxi%402x.png");
      }

      &.item6 {
        background-image: url("https://down.qwdata.vip/app_icon/home/tiubiao%402x.png");
      }

      &.item7 {
        background-image: url("https://down.qwdata.vip/app_icon/home/difang%402x.png");
      }

      &.item8 {
        background-image: url("https://down.qwdata.vip/app_icon/home/yicuoti%402x.png");
      }
    }

    .label {
      font-size: 13px;
      color: #252525;
      margin-top: 15px;
    }
  }
}

.monikaochang {
  width: 460px;
  margin-bottom: 15px;
  margin-left: 20px;
  box-shadow: 0 2px 10px #5c5c5c1a;
  cursor: pointer;
  border-radius: 14px;
  &:hover {
    box-shadow: 0 2px 10px rgba(92, 92, 92, 0.5);
  }
}
</style>
