import { commonApi, examResultApi, questionApi } from '@/api'
import Storage from '@/libs/Storage'

export default {
  namespaced: true,
  state: {
    kemu1: {
      tagCount: 0,
      _1vs1: false,
      shunxuTotalCount: 0,
      shunxuFinishedCount: 0,
      shunxuWrongCount: 0,
      shunxuCorrectCount: 0,
      wrongTotalCount: 0,
      wrongTodayCount: 0,
      collectTotalCount: 0,
      collectTodayCount: 0,
      kaoshiSuccessCount: 0,
      kaoshiFailCount: 0,
      kaoshiCount: 0
    },
    kemu4: {
      tagCount: 0,
      _1vs1: false,
      shunxuTotalCount: 0,

      shunxuFinishedCount: 0,
      shunxuWrongCount: 0,
      shunxuCorrectCount: 0,

      wrongTotalCount: 0,
      wrongTodayCount: 0,

      collectTotalCount: 0,
      collectTodayCount: 0,

      kaoshiSuccessCount: 0,
      kaoshiFailCount: 0,
      kaoshiCount: 0
    }
  },
  mutations: {
    answer (state, data) {
      if (data.type === '1v1') {
        state['kemu' + data.kemu]._1vs1 = true
        Storage.set('kemu' + data.kemu + data.type, 1)
      }

      if (data.status === -1 && data.newWrong) {
        state['kemu' + data.kemu].wrongTodayCount++
        state['kemu' + data.kemu].wrongTotalCount++
      }

      if (data.type === 'shunxu') {
        if (data.status === -1) {
          state['kemu' + data.kemu].shunxuWrongCount++
        } else {
          state['kemu' + data.kemu].shunxuCorrectCount++
        }

        state['kemu' + data.kemu].shunxuFinishedCount++
      }
    },
    collect (state, data) {
      state['kemu' + data.kemu].collectTotalCount++
      state['kemu' + data.kemu].collectTodayCount++
    },
    uncollect (state, data) {
      state['kemu' + data.kemu].collectTotalCount--
      state['kemu' + data.kemu].collectTodayCount--
    },
    kaoshi (state, data) {
      state['kemu' + data.kemu].kaoshiCount++
      if (data.status) {
        state['kemu' + data.kemu].kaoshiSuccessCount++
      } else {
        state['kemu' + data.kemu].kaoshiFailCount++
      }
    },
    init (state, data) {
      state.kemu1 = data.kemu1
      state.kemu4 = data.kemu4
      Storage.get('kemu11v1').then(res => state.kemu1._1vs1 = res)
    }
  },
  actions: {
    async refresh ({ commit, state }) {

    },
    async questionList ({ commit, state }, { type, kemu, kws, today }) {
      if (type === 'collect') {
        const res = await questionApi.collects(kemu, today)
        return res.data.data
      } else if (type === 'wrong') {
        const res = await questionApi.wrongs(kemu, today)
        return res.data.data
      } else {
        const res = await questionApi.list(type, kemu, kws)
        return res.data.data
      }
    },
    async questionInfo ({ commit, state }, id) {
      const res = await questionApi.info(id)
      const question = res.data.data
      if (question.type === 1) {
        question.typeStr = '单选题'
      } else if (question.type === 2) {
        question.typeStr = '多选题'
      } else if (question.type === 3) {
        question.typeStr = '判断题'
      }
      question.answer += ''
      const answer = question.answer
      let answerStr = ''
      if (answer.indexOf('1') > -1) {
        answerStr += 'A'
      }
      if (answer.indexOf('2') > -1) {
        answerStr += 'B'
      }
      if (answer.indexOf('3') > -1) {
        answerStr += 'C'
      }
      if (answer.indexOf('4') > -1) {
        answerStr += 'D'
      }
      question.answerStr = answerStr
      question.result = []
      return question
    },
    async questionAnswer ({ commit, state }, { type, kemu, id, result, status, index }) {
      const res = await questionApi.answer(type, id, result, status, kemu)
      const { newWrong } = res.data.data
      commit('answer', { type, kemu, questionId: id, result, status, newWrong, createdAt: Date.now() })
      return newWrong
    },
    async kaoshiSubmit ({ commit, state }, { type, score, status, time, kemu }) {
      await examResultApi.save(type, score, status, time, kemu)
      commit('kaoshi', { type, kemu, score, status, time, createdAt: Date.now() })
    },
    async tagList ({ commit, state }, kemu) {
      const res = await questionApi.tags(kemu)
      return res.data.data
    },
    async signCategoryList ({ commit, state }) {
      const res = await questionApi.categories()
      return res.data.data.list
    },
    async signCategoryInfo ({ commit, state }, id) {
      const res = await questionApi.signCategoryInfo(id)
      return res.data.data
    },
    async signInfo ({ commit, state }, { cateId, id }) {
      const res = await questionApi.icon(cateId, id)
      return res.data.data
    },
    async kaoshiList ({ commit, state }, kemu) {
      const res = await examResultApi.list('moni', kemu)
      return res.data.data.list
    },
    async questionCollect ({ commit, state }, { id, kemu }) {
      await questionApi.collect(id, kemu)
      commit('collect', { questionId: id, kemu })
    },
    async questionUncollect ({ commit, state }, { id, kemu }) {
      await questionApi.unCollect(id, kemu)
      commit('uncollect', { questionId: id, kemu })
    },
    async initUserData ({ commit }) {
      const res = await commonApi.userData()
      commit('init', res.data.data)
    }
  },
  getters: {
    kemu1: state => {
      return state.kemu1
    },
    kemu4: state => {
      return state.kemu4
    }
  }
}
