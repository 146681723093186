import store from '@/store'
import appConfig from '@/appConfig'
import CryptoJS from '@/vendor/crypto'
import Storage from '@/libs/Storage'
export const styleBgImageUrl = (url) => {
  return 'url("' + url + '")'
}
const utils = {
  sleep (time) {
    return new Promise(resolve => {
      setTimeout(resolve, time)
    })
  },
  encrypt (content, pwd = '') {
    return CryptoJS.AES.encrypt(content, appConfig.pwd + pwd).toString()
  },
  decrypt (content, pwd = '') {
    return JSON.parse(CryptoJS.AES.decrypt(content, appConfig.pwd + pwd).toString(CryptoJS.enc.Utf8))
  },
  playMusic (url) {
    const audio = new Audio(url)
    return audio
  }
}

export default utils
