<template>
  <div class="kemu-page">
    <div class="mobile-page">
      <div class="detail-container">
        <div class="top-container" v-if="inited">
          <div class="item color-local" @click="toLocation">
            <text class="title">地方题</text>
            <text class="sub-title">共8个地区</text>
          </div>
          <div class="item color-first" @click="toEasyWrong">
            <text class="title">易错题</text>
            <text class="sub-title">共{{ topData.easyWrong.count }}题</text>
          </div>
          <div class="item color-second" @click="toIcon()">
            <text class="title">图标大全</text>
            <text class="sub-title">共{{ topData.signIcon.count }}题</text>
          </div>
        </div>
        <div class="list-container">
          <div class="item" v-for="(item, i) in list" :key="item.id" @click="toDetail(item)">
            <div class="no">{{ i + 1 }}</div>
            <div class="flex v f1">
              <div class="label flex">
                {{ item.title }}
              </div>
              <div class="flex">
                <div class="precount-wrap">
                  <div class="precount-bg"></div>
                  <div class="precount" :style="{width : item.precent+ '%'}"></div>
                </div>
                <text class="precent_label">{{ item.precent + '%' }}</text>
                <div class="f1"></div>
                <div class="count">{{ item.count }}题</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { questionApi } from '@/api'

export default {
  data () {
    return {
      inited: false,
      list: [],
      kemu: null,
      topData: {
        easyWrong: {
          count: '-'
        },
        signIcon: {
          count: '-'
        }
      }
    }
  },
  computed: {

  },
  async created () {
    const { kemu } = this.$route.query
    this.kemu = kemu
    const res = await questionApi.tags(kemu)
    this.list = res.data.data.list
    this.topData = res.data.data.topData
    this.inited = true
  },
  methods: {
    toLocation () {
      // eslint-disable-next-line eqeqeq
      if (this.kemu == 1) {
        this.$router.push({
          name: 'difangti',
          query: {
            kemu: 1
          }
        })
      } else {
        this.$router.push({
          name: 'difangti4',
          query: {
            kemu: 4
          }
        })
      }
    },
    toDetail (item) {
      // eslint-disable-next-line eqeqeq
      if (this.kemu == 1) {
        this.$router.push({
          name: 'kaochang_enter',
          query: {
            kemu: 1,
            type: 'tag_' + item.id
          }
        })
      } else {
        this.$router.push({
          name: 'kaochang_enter4',
          query: {
            kemu: 4,
            type: 'tag_' + item.id
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.detail-container {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;

  .top-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    //margin-left: 17px;
    //margin-right: 17px;

    .item {
      display: flex;
      flex: 1;
      height: 80px;
      flex-direction: column;
      justify-content: center;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
    }

    .color-local {
      background-image: url("@/static/tag/3.png");
      margin-left: 30px;

      .title {
      }
    }

    .color-first {
      background-image: url("@/static/tag/1.png");
      margin-left: 20px;
    }

    .color-second {
      background-image: url("@/static/tag/2.png");
      margin-left: 20px;
      margin-right: 30px;
    }

    .title {
      font-size: 20px;
      color: #FFFFFF;
      margin-left: 20px;
    }

    .sub-title {
      font-size: 18px;
      color: #FFFFFF;
      margin-top: 5px;
      margin-left: 20px;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .icon-watermark {
      width: 124px;
      height: 60px;
      position: absolute;
      bottom: 0px;
      right: 0px;
    }
  }

  .list-container {
    // margin-top: calc(var(--status-bar-height) + 76px + 40px);
    display: flex;
    flex-wrap: wrap;

    .item {
      display: flex;
      width: 250px;
      height: 90px;
      align-items: center;
      border-bottom: 1px solid #f5f5f5;
      padding: 0 15px;
      box-sizing: border-box;
      cursor: pointer;

      .no {
        width: 25px;
        height: 25px;
        border: 1px solid #3073F6;
        border-radius: 24px;
        color: #3073F6;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        margin-right: 15px;
      }

      .label {
        font-size: 16px;
        margin-bottom: 8px;
      }

      .count {
        color: #929292;
        font-size: 14px;
        text-align: right;
      }

      .precount-bg {
        background-color: #ecf3ff;
        height: 10px;
        border-radius: 17px;
        width: 90px;
      }

      .precount-wrap {
        position: relative;
        width: 90px;
        margin-top: 5px;
        margin-right: 10px;
      }

      .precent_label {
        font-size: 14px;
        color: #879eca;
      }

      .precount {
        background-color: #3073F6;
        height: 10px;
        border-radius: 17px;
        position: absolute;
        top: 0;
        z-index: 1;
        bottom: 0;
      }
    }
  }
}
</style>
