import Storage from '@/libs/Storage'

export default {
  namespaced: true,
  state: {
    homeNeedPushVIP: 1,
    freeOpenAnswerCount: 5,
    getawayDialogIndex: -1
  },
  mutations: {
    dismissHomeNeedPushVIP (state) {
      state.homeNeedPushVIP = 0
    },
    freeOpenAnswerCount (state, freeOpenAnswerCount) {
      state.freeOpenAnswerCount = freeOpenAnswerCount
    },
    changeDialogIndex (state) {
      if (state.getawayDialogIndex < 2) {
        state.getawayDialogIndex = state.getawayDialogIndex + 1
      } else {
        state.getawayDialogIndex = 0
      }
    }
  },
  actions: {
    dismissHomeNeedPushVIP ({ commit }) {
      commit('dismissHomeNeedPushVIP')
    },
    async init ({ commit }) {
      const count = await Storage.get('freeOpenAnswerCount', 5)
      commit('freeOpenAnswerCount', count)
    },
    deductFreeOpenAnswerCount ({ commit, state }) {
      console.log('state.freeOpenAnswerCount', state.freeOpenAnswerCount)
      if (state.freeOpenAnswerCount > 0) {
        commit('freeOpenAnswerCount', state.freeOpenAnswerCount - 1)
        Storage.set('freeOpenAnswerCount', state.freeOpenAnswerCount - 1)
        return true
      }
      return false
    },
    changeDialogIndex ({ commit, state }) {
      commit('changeDialogIndex')
    }
  },
  getters: {
    homeNeedPushVIP: state => {
      return state.homeNeedPushVIP
    },
    freeOpenAnswerCount: state => {
      return state.freeOpenAnswerCount
    },
    getawayDialogIndex: state => {
      return state.getawayDialogIndex
    }
  }
}
