<template>
  <div class="video_less_view" @click="goTopicVideo()">
    <div class="title">{{ title || '科目一 · 3小时精讲' }}</div>
    <div class="block-container4">
      <div class="pic"
            :style="{'background-image': styleBgImageUrl('https://down.qwdata.vip/app_icon/1701420810566')}">
        <div class="bofang"></div>
      </div>
      <div class="people" v-if="showPeople">1.3万人正在看</div>
      <div class="flex v f1" v-if="tips1">
        <div class="tips1">{{ tips1 }}</div>
        <div class="tips2">{{ tips2 }}</div>
        <div class="f1"></div>
        <div class="btn">{{ btnText || '看视频' }}</div>
      </div>
      <div class="flex v f1" v-else>
        <div class="label">{{ labels[0] }} {{ labels[1] }}</div>
        <div class="label">{{ labels[2] }} {{ labels[3] }}</div>
        <div class="label">{{ labels[4] }} {{ labels[5] || '' }}</div>
        <div class="f1"></div>
        <div class="btn">{{ btnText || '看视频' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { styleBgImageUrl } from '@/libs/utils'

export default {
  name: 'VideoLessonView',
  props: ['CourseId', 'title', 'videos', 'questionId', 'btnText', 'tips1', 'tips2', 'showPeople'],
  data () {
    return {}
  },
  computed: {
    labels () {
      return [
        '易考题',
        '扣分题',
        '罚款题',
        '超速题',
        '标志题'
      ]
    }
  },
  methods: {
    styleBgImageUrl,
    goTopicVideo (id = null) {
      this.$emit('videoClick')
      const query = {
        courseId: 'C000003'
      }
      if (id) {
        query.contentId = id
      }
      if (this.questionId) {
        query.questionId = this.questionId
      }

      this.$router.replace({
        name: 'jingjiang',
        query
      })
    }
  }
}
</script>

<style lang="less">
.video_less_view {
  box-sizing: border-box;
  box-shadow: 0 2px 10px #5c5c5c1a;
  background-image: linear-gradient(92deg, #FFE8E4 100%, #FFEDE9 43%, #FFF7F3 0%);
  width: 460px;
  padding: 18px;
  margin: 0 20px;
  margin-bottom: 15px;
  border-radius: 15px;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
  &:hover {
    box-shadow: 0 2px 10px rgba(92, 92, 92, 0.5);
  }
  .title {
    font-size: 18px;
    color: #48110D;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .people {
    position: absolute;
    right: 35px;
    top: 43px;
    color: rgba(72, 17, 13, 0.8);
    font-size: 12px;
  }

  .block-container4 {
    display: flex;

    .pic {
      width: 245px;
      height: 140px;
      border-radius: 24px;
      border: 3px solid #fff;
      margin-right: 20px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .bofang {
        background-image: url("http://down.qwdata.vip/app_icon/1689063074661");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 72px;
        height: 72px;
      }
    }

    .label {
      font-size: 16px;
      color: rgba(72, 17, 13, 0.9);
      font-weight: bold;
      line-height: 30px;
    }
    .tips1, .tips2 {
      font-size: 16px;
      color: rgba(72, 17, 13, 0.9);
      font-weight: bold;
      margin-top: 5px;
      line-height: 22px;
    }
  }

  .btn {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: #fff;
    background-color: #FD6144;
    border-radius: 100px;
  }
}
</style>
