import { commonApi } from '@/api'
import { createStore } from 'vuex'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
import Storage from '@/libs/Storage'
import question from '@/store/modules/questionWeb'
import args from '@/store/modules/args'
import appConfig from '@/appConfig'
export default createStore({
  modules: {
    question,
    args
  },
  state: {
    mine: null,
    location: null,
    settings: null,
    kemu: 1,
    checkedLogined: false,
    carTypes: [
      {
        title: '小车',
        value: 1,
        card: 'C1/C2/C3'
      },
      {
        title: '货车',
        value: 2,
        card: 'A2/B2'
      },
      {
        title: '客车',
        value: 3,
        card: 'A1/A3/B1'
      },
      {
        title: '摩托车',
        card: 'D/E/F',
        value: 4
      }
    ]
  },
  mutations: {
    login (state, {
      user,
      settings,
      location
    }) {
      state.mine = user
      state.location = location
      state.settings = settings
    },
    checkedLogined (state) {
      state.checkedLogined = true
    },
    changeKemu (state, kemu) {
      state.kemu = kemu
    },
    logout (state) {
      setTimeout(() => {
        state.mine = null
        state.settings = null
        state.location = null
      }, 300)
      state.checkedLogined = false
    }
  },
  actions: {
    async login ({ commit }, qrcode) {
      try {
        const res = await commonApi.scanLogin(qrcode)
        console.log('------', res)
        commit('login', res.data.data)
        commit('checkedLogined')
        ElMessage({
          type: 'success',
          message: '登录成功，欢迎回来：' + res.data.data.user.name
        })
        return true
      } catch (e) {
        console.log('======', e)
      }
      return false
    },

    async checkLogined ({
      commit,
      state
    }) {
      if (state.checkedLogined) {
        return !!state.mine
      }

      commit('checkedLogined')

      try {
        const res = await commonApi.checkLogined()

        commit('login', res.data.data)
        return true
      } catch (e) {
        console.log(e)
      }
      return false
    },
    changeKemu ({ commit }, data) {
      commit('changeKemu', data)
    },
    async logout ({ commit }) {
      await Storage.del(appConfig.authTokenKey)
      commit('logout')
    }
  },
  getters: {
    isFanzhishi: state => {
      if (state.mine) {
        return state.mine.type === 1 || state.mine.type === 2
      }
    },
    menus: state => {
      const menus = []

      return menus.concat([])
    },
    isLogined: state => {
      return !!state.mine
    },
    mine: state => {
      return state.mine
    },
    settings: state => {
      return state.settings
    },
    haveVIP: state => {
      if (state.mine && (state.mine.vipEndAt1 || state.mine.vipEndAt2 || state.mine.vipEndAt3 || state.mine.vipEndAt4)) {
        return true
      }
      return false
    },
    location: state => {
      return state.location
    },
    selectedCarType: state => {
      if (state.mine) { return state.mine.carType }
      return 1
    },
    selectedCarTypeObj1: state => {
      let carType = 1
      if (state.mine) { carType = state.mine.carType }
      return state.carTypes[carType - 1]
    },
    carTypes: state => {
      return state.carTypes
    }
  }
})
