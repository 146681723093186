export default {
  set (key, obj) {
    return new Promise(resolve => {
      localStorage.setItem(key, JSON.stringify(obj))
      resolve()
    })
  },
  get (key, def = null) {
    return new Promise(resolve => {
      const data = localStorage.getItem(key)
      if (data === null) {
        return resolve(def)
      }
      try {
        resolve(JSON.parse(data))
      } catch (e) {
        resolve(data)
      }
    })
  },
  del (key) {
    return new Promise(resolve => {
      localStorage.removeItem(key)
      resolve()
    })
  }
}
