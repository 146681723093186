<template>
  <div class="mine-view-container">
<!--    <div class="result-container">-->
<!--      <div class="tab-container">-->
<!--        <div :class="['tab', selected===1?'selected':'']">-->
<!--          <text>科一</text>-->
<!--          <img src="@/static/bj@3x.png" class="icon" v-if="selected===1">-->
<!--        </div>-->
<!--        <div :class="['tab', selected===4?'selected':'']">-->
<!--          <text>科四</text>-->
<!--          <img src="@/static/bj@3x.png" class="icon" v-if="selected===4">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="body">-->
<!--        <div class="item" @click="toExamResult">-->
<!--          <text class="count-text">{{ kaoshiCount }}</text>-->
<!--          <text class="label">考试次数</text>-->
<!--        </div>-->
<!--        <div class="item" @click="toExamResult">-->
<!--          <text class="count-text">{{ kaoshiSuccessCount }}</text>-->
<!--          <text class="label">合格次数</text>-->
<!--          <text class="success">90分合格</text>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="mine-btn-container">
      <div class="mine-btn" @click="goTo('mine_wrong')">
        <div class="flex v f1">
          <text>我的错题</text>
          <text class="count-text">{{ wrongTotalCount }}题</text>
        </div>
        <img src="@/static/icon_cuoti@3x.png" class="mine-btn-icon">
      </div>
      <div class="mine-btn" @click="goTo('mine_collect')">
        <div class="flex v f1">
          <text>我的收藏</text>
          <text class="count-text">{{ collectTotalCount }}题</text>
        </div>
        <img src="@/static/icon_shoucang@3x.png" class="mine-btn-icon">
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DataView',
  props: ['kemu'],
  data () {
    return {
      selected: 1
    }
  },
  computed: {
    ...mapGetters('question', ['kemu1', 'kemu4']),
    wrongTotalCount () {
      return this.kemu1.wrongTotalCount + this.kemu4.wrongTotalCount
    },
    collectTotalCount () {
      return this.kemu1.collectTotalCount + this.kemu4.collectTotalCount
    },
    kaoshiCount () {
      if (this.selected === 1) { return this.kemu1.kaoshiCount }
      return this.kemu4.kaoshiCount
    },
    kaoshiSuccessCount () {
      if (this.selected === 1) { return this.kemu1.kaoshiSuccessCount }
      return this.kemu4.kaoshiSuccessCount
    }
  },
  created () {
    this.selected = this.kemu
  },
  methods: {
    goTo (page) {
      this.$router.push({
        path: page,
        query: {
          kemu: this.kemu
        }
      })
    },
    toExamResult () {
      this.$router.push({
        path: 'exam_result',
        query: {
          kemu: this.selected
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.mine-view-container {
  display: flex;
  flex-direction: column;
  .result-container {
    margin: 15px;
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
    .tab-container {
      background-color: #3073f6;
      display: flex;
      color: #fff;
      font-size: 16px;

      .tab {
        height: 45px;
        line-height: 45px;
        width: 100px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 40px;
        cursor: pointer;

        &.selected {
          color: #000;
        }

        text {
          z-index: 2;
          text-align: center;
          position: absolute;
        }

        .icon {
          height: 50px;
          width: 150px;
          position: absolute;
          left: -25px;
          top: 0;
          z-index: 1;
        }
      }
    }

    .body {
      padding: 15px;
      display: flex;
      position: relative;

      .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .item-bg {
          width: 244px;
          height: 172px;
          position: absolute;
          right: 0;
          top: 0;
        }

        .count-text {
          font-size: 25px;
          z-index: 1;
        }

        .title {
          font-size: 20px;
        }

        .success {
          font-size: 14px;
          color: #999;
          margin-top: 10px;
        }
      }
    }
  }

  .mine-btn-container {
    display: flex;
    margin: 15px;
    margin-top: 0;

    .mine-btn-icon {
      width: 60px;
      height: 60px;
    }

    .mine-btn {
      flex: 1;
      cursor: pointer;
      background-color: #fff;
      border-radius: 15px;
      margin-left: 15px;
      padding: 15px;
      display: flex;
      font-weight: 500;
      font-size: 16px;

      &:first-child {
        margin-right: 0;
        margin-left: 0;
      }

      .count-text {
        color: #999;
        font-size: 16px;
        margin-top: 10px;
      }
    }
  }
}
</style>
