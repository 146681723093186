import http from '@/util/http'
import ResourceApi from '@/util/rest.js'
import appConfig from '@/appConfig'
import utils from '@/libs/utils'
export const commonApi = {
  scanLogin (token) {
    return http.post('user/web/scanLogin', {
      data: {
        token
      }
    })
  },
  checkLogined () {
    return http.get('user/web/logined')
  }
}

export const questionApi = {
  info (id) {
    // id = '14632005'
    // id = '14632004'
    // id = '14632001'
    // id = '14631977'
    return http.get('questions/' + id + '/info').then(res => {
      res.data.data = utils.decrypt(res.data.data, id)
      // console.log(res.data.data)
      return res
    })
  },
  yuyinAll (id) {
    const url = 'yuyin/questions/' + id + '/all'
    return http.get(url)
  },
  locationList (kemu = 1) {
    return http.get('location/questions?kemu=' + kemu)
  },
  mijuanList (kemu) {
    return http.get('questions/categories?kemu=' + kemu)
  },
  mijuanInfo (id) {
    return http.get('questions/categories/' + id)
  },
  list (type, kemu = null, kws = null) {
    let url = 'questions?type=' + type
    if (kws) {
      url += '&kws=' + kws
    }

    if (kemu) {
      url += '&kemu=' + kemu
    }
    return http.get(url)
  },
  tagInfo (id) {
    return http.get('questions/tags/' + id)
  },
  tags (kemu) {
    return http.get('tags?kemu=' + kemu)
  },
  tagData (type) {
    return http.get('tag?tagId=' + type)
  },
  tagCount () {
    return http.get('tags/count')
  },
  signCategoryInfo (categoryId) {
    return http.get('categories/' + categoryId + '/sign_icons')
  },
  icon (categoryId, id) {
    return http.get('categories/' + categoryId + '/sign_icons/' + id)
  },
  categories () {
    return http.get('categories')
  },
  collect (questionId, kemu) {
    return http.post('collect', {
      data: {
        questionId, kemu
      }
    })
  },

  collects (kemu, today, userId) {
    let url = 'collects?kemu=' + kemu
    if (today) {
      url += '&today=' + today
    }
    if (userId) {
      url += '&userId=' + userId
    }
    return http.get(url)
  },

  wrongs (kemu, today) {
    let url = 'wrongs?kemu=' + kemu
    if (today) {
      url += '&today=' + today
    }
    return http.get(url)
  },

  unCollect (questionId, kemu) {
    return http.post('unCollect', {
      data: {
        questionId, kemu
      }
    })
  },

  clearAnswer (type) {
    return http.post('questions/answer/clear', {
      data: {
        type
      }
    })
  },
  answer (type, questionId, result, status, kemu) {
    return http.post('questions/answer', {
      data: {
        type, questionId, result, status, kemu
      }
    })
  },
  preExam (type) {
    return http.get('questions/count?type=' + type)
  },
  deleteWrong (questionId) {
    return http.post('deleteWrong', {
      data: {
        questionId
      }
    })
  }
}
export const examResultApi = {
  list (key, kemu) {
    const url = 'exam_results?key=' + key + '&kemu=' + kemu
    return http.get(url)
  },
  count () {
    return http.get('exam_results/count')
  },
  save (key, score, status, time, kemu) {
    return http.post('exam_results', {
      data: {
        key, score, status, kemu, time
      }
    })
  }
}
export const courseApi = {
  info (id) {
    return http.get('courses/' + id)
  },
  content (contentId) {
    return http.get('courses/contents/' + contentId)
  },
  contentsByTag (id, tagId) {
    return http.get('courses/' + id + '/contentsByTag?tagId=' + tagId)
  },
  contentsByQuestion (id, questionId) {
    return http.get('courses/' + id + '/contentsByQuestion?questionId=' + questionId)
  },
  postCourseToMail (courseId, mail) {
    return http.post('courses/' + courseId + '/mail', {
      data: {
        mail
      }
    })
  }
}
