<template>
  <router-view/>
</template>

<style lang="less">
  @import "app";
  body {
    user-select: none;
  }
  .kemu-page {
    height: 100%;
    display: flex;
    .mobile-page {
      width: 500px;
      min-width: 500px;
      background-color: #fff;
      box-shadow: 5px 0 5px rgba(92, 92, 92, 0.1);
      border-left: 1px solid #eee;
      z-index: 2;
      min-height: 100%;

      overflow: auto;
      /* 隐藏滚动条 */
      &::-webkit-scrollbar {
        display: none;
      }

    }
    .right-page {
      flex: 1;
      display: flex;
    }
  }
</style>
