<template>
  <div class="kemu-page">
    <div class="mobile-page">
      <div class=" shorthand-items-container">
        <div class="shorthand-item" v-for="(item,i) in list" :key="i" @click="itemAction(item)">
          <text class="city">{{ item.city }}</text>
          <text class="title">{{ item.title }}</text>
          <text class="desc">(共{{ item.count || 0 }}题)</text>
          <div class="num">{{ pinyin[item.city] }}</div>
          <div class="left"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { questionApi } from '@/api'

export default {
  data () {
    return {
      list: [],
      pinyin: {
        上海: 'SH',
        湖南: 'HN',
        浙江: 'ZZ',
        江西: 'JX',
        四川: 'SC',
        福州: 'FZ',
        济南: 'JN',
        天津: 'TJ',
        河南: 'HN',
        贵州: 'GZ',
        重庆: 'CQ'
      }
    }
  },
  async created () {
    const { kemu } = this.$route.query
    this.kemu = kemu
    const res = await questionApi.locationList(kemu)
    this.list = res.data.data.list
    this.list.map(item => {
      item.city = item.title.split('地方题')[0]
    })
  },
  methods: {
    async itemAction (item) {
      // uni.navigateTo({
      //   url: `/pages/detail/detail?type=tag_${item.id}&kemu=` + this.kemu + `&title=${item.title}` + '&bigType=location_question'
      // })
      // eslint-disable-next-line eqeqeq
      if (this.kemu == 1) {
        this.$router.push({
          path: 'kaochang_enter',
          query: {
            kemu: 1,
            type: 'tag_' + item.id
          }
        })
      } else {
        this.$router.push({
          name: 'kaochang_enter4',
          query: {
            kemu: 4,
            type: 'tag_' + item.id
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.kemu-page {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.shorthand-items-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;

  .shorthand-item {
    margin-left: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 225px;
    height: 160px;
    margin-bottom: 15px;
    border-radius: 20px;
    background: linear-gradient(180deg, #678DFF 0%, #9FB7FF 100%);
    color: #fff;
    padding-top: 20px;
    padding-left: 52px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      box-shadow: 0 2px 10px rgba(92, 92, 92, 0.5);
    }

    .left {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 40px;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .location {
      width: 32px;
      height: 40px;
    }

    .num {
      font-size: 90px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.4);
      position: absolute;
      right: 0;
      bottom: -20px;
    }

    .city {
      font-size: 22px;
      font-weight: bold;
    }

    .title {
      font-weight: bold;
      font-size: 15px;
      margin-top: 10px;
    }

    .desc {
      margin-top: 5px;
      font-size: 14px;
    }
  }
}
</style>
