<template>
  <div class="kemu-page">
    <div class="mobile-page">
      <div class="shorthand-container">
        <div class="bg"/>
        <div class="shorthand-item-list">
          <div class="shorthand-item" v-for="(item,i) in list" :key="i">
            <div class="flex v f1">
              <text class="shorthand-title">{{ item.title }}</text>
              <!--        <text class="shorthand-sub-title">共{{ item.count || 0 }}题</text>-->
            </div>
            <div class="btn" @click="toKaochang(item)">开始练习</div>
            <!--      <image class="shorthand-arrow" src="/static/arrow.png"></image>-->
          </div>
        </div>
        <div class="tips-container">
          <div class="tips-title">温馨提示</div>
          <div class="tips-row">1、密卷涵盖了几乎所有的常考知识点，建议考前做完所有密卷题。</div>
          <div class="tips-row">2、建议密卷与精选500题一起练习，同时巩固复习错题本。</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { questionApi } from '@/api'

export default {
  data () {
    return {
      list: []
    }
  },
  async created () {
    this.kemu = this.$route.query.kemu
    const res = await questionApi.mijuanList(this.kemu)
    this.list = res.data.data.list
  },
  methods: {
    itemAction (item) {
      // uni.navigateTo({
      //   url: `/pages/detail/detail?from=mijuan&type=category_${item.id}&title=${item.title}&kemu=` + this.kemu
      // })
    },
    toKaochang (item) {
      // eslint-disable-next-line eqeqeq
      if (this.kemu == 1) {
        this.$router.push({
          path: 'kaochang_enter',
          query: {
            kemu: 1,
            type: 'category_' + item.id
          }
        })
      } else {
        this.$router.push({
          name: 'kaochang_enter4',
          query: {
            kemu: 4,
            type: 'category_' + item.id
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.shorthand-container {
  display: flex;
  flex-direction: column;

  .bg {
    width: 500px;
    height: 250px;
    background-image: url("https://down.qwdata.vip/app_icon/1703679209369");
    background-size: 100% auto;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .shorthand-item-list {
    background-color: #f8f8f8;
    margin-top: -35px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-top: 5px;

    .shorthand-item {
      display: flex;
      height: 70px;
      align-items: center;
      background-color: #fff;
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.03);
      border-radius: 15px;
      margin: 15px;
    }
  }

  .btn {
    color: #fff;
    font-size: 16px;
    height: 40px;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    border-radius: 50px;
    background-image: linear-gradient(to right, rgb(22, 106, 245), rgb(43, 155, 250));
    cursor: pointer;
  }

  .shorthand-icon {
    width: 68px;
    height: 68px;
    margin-left: 28px;
  }

  .shorthand-title {
    color: #000000;
    font-size: 18px;
    margin-left: 20px;
    font-weight: bold;
    flex: 1;
  }

  .shorthand-sub-title {
    color: #A0A0A0;
    font-size: 12px;
    margin-top: 8px;
    margin-left: 30px;
  }

  .shorthand-arrow {
    width: 8px;
    height: 16px;
    margin-right: 34px;
  }

  .tips-container {
    padding: 20px 15px;
  }

  .tips-title {
    color: #EC4E36;
    font-weight: bold;
    font-size: 17px;
  }

  .tips-row {
    font-size: 14px;
    color: #1A1E27;
    line-height: 23px;
    margin-top: 20px;
  }
}
</style>
