<template>
  <div id="global-header">
    <div class="logo" v-if="settings">
      <img v-if="settings.logo" class="icon" :src="settings.logo+'?imageView2/1/w/50/h/50'">
      <div class="flex v appName">
        <div>{{ settings.shortName || settings.appName }}</div>
        <!--        <div v-if="location" class="city">{{ location.name }}题库</div>-->
        <!--        <div v-else class="city">全国题库</div>-->
      </div>
    </div>
    <div class="kemu-nav">
      <div v-for="opt in options" :key="opt.value" :class="['item', opt.value === kemu?'active':'']"
           @click="toChangeKemu(opt.value)">{{ opt.label }}
      </div>
    </div>
<!--    <el-select class="cartype-container">-->
<!--      <el-option v-for="carType in carTypes" :key="carType.value">{{carType.title}}</el-option>-->
<!--    </el-select>-->
    <div class="f1"></div>
    <div class="vip-container">
      <img src="@/static/vip_icon@3x.png" class="vip-icon">
      <template v-if="haveVIP">
        <div class="f1" style="display: flex;flex-direction: column;align-items: baseline">
          <div v-if="mine.vipProduct" style="font-size: 14px">已开通：{{ mine.vipProduct.title }}</div>
          <div v-else>恭喜，您已开通VIP功能！</div>
          <div style="font-size: 12px;opacity: 0.5;margin-top: 2px">
            到期时间：{{ dateFormat(mine.vipEndAt || mine.vipEndAt1 || mine.vipEndAt4) }}
          </div>
        </div>
      </template>
      <template v-else-if="mine && mine.vipExpiredStr">
        <div class="f1" style="display: flex;align-items: baseline">您的VIP已到期
          <div style="font-size: 20px;">
            （{{ mine.vipExpiredStr }}）
          </div>
        </div>
        <div class="look-btn" @click="toInvite">续费开通</div>
      </template>
      <template v-else>
        <span>开通会员，获取高级功能</span>
        <div class="look-btn" @click="toInvite">去看看</div>
      </template>
    </div>
    <AvatarDrop></AvatarDrop>
  </div>
</template>

<script>
import AvatarDrop from '@/components/AvatarDrop'
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'HeaderView',
  components: {
    AvatarDrop
  },
  data () {
    console.log(this.$route)
    let kemu

    if (this.$route.path.indexOf('/kemu1') === 0 || this.$route.path === '/') {
      kemu = 1
    } else if (this.$route.path.indexOf('/kemu2') === 0) {
      kemu = 2
    } else if (this.$route.path.indexOf('/kemu3') === 0) {
      kemu = 3
    } else if (this.$route.path.indexOf('/kemu4') === 0) {
      kemu = 4
    }

    console.log('-----', kemu)

    return {
      kemu,
      options: [
        {
          label: '科目一',
          value: 1
        },
        {
          label: '科目二',
          value: 2
        },
        {
          label: '科目三',
          value: 3
        },
        {
          label: '科目四',
          value: 4
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['settings', 'haveVIP', 'mine', 'location', 'carTypes'])
  },
  methods: {
    ...mapActions(['changeKemu']),
    toChangeKemu (kemu) {
      console.log('xxxxxxxxx', kemu)
      this.kemu = kemu
      this.$router.replace({
        name: 'kemu' + kemu + '_home'
      })
    },
    dateFormat (time) {
      return moment(time).format('YYYY-MM-DD')
    }
  }
}
</script>

<style scoped lang="less">

#global-header {
  height: var(--el-header-height);
  display: flex;

  .el-segmented {
    //--el-segmented-item-selected-color: var(--el-text-color-primary);
    --el-segmented-item-selected-bg-color: #CEBC9A;
    --el-border-radius-base: 16px;
  }

  .kemu-nav {
    display: flex;
    background-color: #f5f7fa;
    color: #555;
    border-radius: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 255px;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      border-radius: 14px;
      margin: 3px 0;
      cursor: pointer;

      &:first-child {
        margin-left: 3px;
      }

      &:last-child {
        margin-right: 3px;
      }

      &.active {
        background-color: #cebd9b;
        color: #fff;
      }

      &:hover {
        background-color: rgba(206, 189, 155, 0.2);

        &.active {
          background-color: #cebd9b;
          color: #fff;
        }
      }
    }
  }

  .logo {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    margin-right: 20px;
    align-items: center;
    word-break: keep-all;

    .icon {
      margin-right: 10px;
      border-radius: 10px;
      width: 50px;
      height: 50px;
    }

    .city {
      font-size: 14px;
      margin-top: 5px;
      color: rgba(255, 255, 255, 0.9);
    }
  }
  .cartype-container {
    margin: 10px;
    width: 150px;
  }

  .vip-container {
    padding: 0 15px;
    margin: 10px 0;
    margin-right: 10px;
    background-color: #222832;
    color: #CEBC9A;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 16px;
    z-index: 1;

    .vip-icon {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }

    span1 {
      flex: 1
    }

    .look-btn {
      color: #222832;
      font-weight: bold;
      background-image: linear-gradient(230deg, #FBDFCD 0%, #FBC59F 95%);
      font-size: 14px;
      padding: 7px 20px;
      border-radius: 40px;
      margin-left: 10px;
    }
  }

  @media (max-width: 1000px) {
    .vip-container {
      display: none;
    }
  }

  @media (max-width: 700px) {
    .appName {
      display: none;
    }

    .logo {
      .icon {
        margin-right: 0;
      }
    }
  }

  .header-btn {
    line-height: 60px;
    height: 57px;
    cursor: pointer;
    padding: 0 20px;
    position: relative;

    &:hover {
      border-bottom: 3px solid var(--el-color-primary);
      color: var(--el-color-primary);
      font-weight: 400;
    }

    &.active {
      border-bottom: 3px solid var(--el-color-primary);
      color: var(--el-color-primary);
      font-weight: 400;
    }
  }
}
</style>
