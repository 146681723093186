<template>
  <div class="kemu-page">
    <div class="mobile-page">
      <div class='Kemu2-container'>
        <div class="icon-header-container">
          <div class="item" v-for="icon in icons" :key="icon.icon" @click="toView(icon)">
            <img class="icon" :src="icon.icon">
            <text class="text">{{ icon.label }}</text>
          </div>
        </div>
        <div class="body-container">
          <div class="flex-row label-container align-center justify-between">
            <div class="main-label">{{ kemuStr }}考试项目解析</div>
            <!--          <div class="all">查看全部</div>-->
          </div>
          <div class="videos-container">
            <div class="video-container" v-for="item in list" :key="item.id" @click="toDetail(item.id)">
              <div class="cover" :style="{'background-image': styleBgImageUrl(item.coverUrl)}">
                <div v-if="item.free !== 1" class="vip">{{ item.free === 0 ? '试看' : 'VIP' }}</div>
              </div>
              <text class="label">{{ item.title }}</text>
            </div>
          </div>
        </div>
      </div>
    </div>
    <iframe class="webview" :src="url" v-if="url"></iframe>
    <div class="right-page" v-if="contentId">
      <VideoView :content-id="contentId"></VideoView>
    </div>
  </div>
</template>
<script>
import { courseApi } from '@/api'
import { styleBgImageUrl } from '@/libs/utils'
import VideoView from '@/components/VideoView.vue'

export default {
  components: { VideoView },
  data () {
    return {
      list: [],
      contentId: null,
      url: null
    }
  },
  computed: {
    kemuStr () {
      return '科二'
    },
    icons () {
      return [
        {
          icon: require('../../static/kemu23/icon1.png'),
          label: '考试标准',
          url: 'https://down.qwdata.vip/html/standard_kemu3.html'
        },
        {
          icon: require('../../static/kemu23/icon2.png'),
          label: '考前必看',
          url: 'https://down.qwdata.vip/html/matter_kemu3.html'
        },
        {
          icon: require('../../static/kemu23/icon3.png'),
          label: '考试流程',
          url: 'https://down.qwdata.vip/html/process_kemu3.html'
        }
      ]
    },
    courseId () {
      return 'QNW000002'
    }
  },
  async mounted () {
    const res2 = await courseApi.info(this.courseId)
    this.list = res2.data.data.contents
  },
  methods: {
    styleBgImageUrl,
    toDetail (id) {
      // uni.navigateTo({
      //   url: '/pages/course_video/course_video?courseId=' + this.courseId + '&contentId=' + id
      // })
      this.contentId = id
      this.url = null
    },
    toView (data) {
      this.contentId = null
      this.url = data.url
    }
  }
}
</script>
<style scoped lang="less">
.webview {
  background-color: #fff;
  flex: 1;
}
.Kemu2-container {
  padding-bottom: 100px;

  .icon-header-container {
    display: flex;

    .item {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: center;
      height: 160px;
      cursor: pointer;

      .icon {
        width: 70px;
        height: 75px;
      }

      .text {
        font-size: 16px;
        color: rgba(102, 102, 102, 1);
      }
    }
  }

  .body-container {
    .label-container {
      margin-bottom: 20px;

      .main-label {
        font-size: 20px;
        font-weight: bold;
        color: #000;
        margin-left: 15px;
      }
    }

    .videos-container {
      display: flex;
      flex-wrap: wrap;

      .video-container {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 20px;
        cursor: pointer;
        .cover {
          width: 225px;
          height: 125px;
          border-radius: 10px;
          background-size: cover;
        }

        &:hover {
          .cover {
            box-shadow: 0 2px 10px rgba(92, 92, 92, 0.5);
          }
        }

        .label {
          margin-top: 10px;
          font-size: 16px;
          color: rgba(53, 60, 90, 1);
          font-weight: bold;
        }

        .vip {
          position: absolute;
          background-image: linear-gradient(112deg, #141819 0%, #1B2229 44%, #2B313E 100%);
          color: #FFE7BB;
          right: 5px;
          top: 5px;
          font-size: 14px;
          padding: 5px 15px;
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
