<template>
  <div class="kemu-page">
    <div class="mobile-page">
      <div class="course_list_container">
        <div :class="['course_item', 'content_'+item.id, contentId === item.id?'active':'']"
             v-for="(item, n) in contents" :key="item.id"
             @click="toDetail(item)">
          <div class="cover_wrapper" :style="{'background-image': styleBgImageUrl(item.coverUrl)}">
            <div class="index">{{ n + 1 }}</div>
          </div>
          <div class="body">
            <div class="title">【{{ item.title }}】答题技巧</div>
            <div class="flex h vc">
              <div class="tag" v-if="n%2===0">秘籍口诀</div>
              <div class="tag2" v-else>好评连连</div>
              <div class="count">{{ readCountFormat(item.readCount) }}人看过</div>
            </div>
            <div class="bottom">
              <div class="text1">付费精讲</div>
              <div class="text2">会员专享</div>
              <div class="btn try" v-if="item.free===0">试 看</div>
              <div class="btn free" v-else-if="item.free===1">免 费</div>
              <div class="btn" v-else>看视频</div>
            </div>
          </div>
        </div>
        <!--        <div class="btn_container" v-if="!kemuVIP" @click="toBuy">-->
        <!--          <div class="pay_btn">-->
        <!--            付费开通全部{{ contents.length }}门课程-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="right-page">
      <VideoView :content-id="contentId"></VideoView>
    </div>
  </div>
</template>
<script>
import { courseApi } from '@/api'
import { styleBgImageUrl } from '@/libs/utils'
import VideoView from '@/components/VideoView.vue'

export default {
  components: { VideoView },
  data () {
    return {
      contents: [],
      contentId: null
    }
  },
  async created () {
    const { courseId } = this.$route.query
    const res = await courseApi.info(courseId)
    this.contents = res.data.data.contents
    this.course = res.data.data
  },
  methods: {
    styleBgImageUrl,
    readCountFormat (count) {
      return Math.round(count / 1000) / 10 + '万'
    },
    async toDetail (item) {
      if (item.free === -1 && !this.kemuVIP) {
        // uni.showToast({
        //   title: '请开通会员！', icon: 'none', duration: 2000
        // })

      }
      console.log(item)

      this.contentId = item.id
      // uni.navigateTo({
      //   url: '/pages/video_detail/video_detail?contentId=' + item.id
      // })
    }
  }
}
</script>

<style scoped lang="less">

.course_list_container {
  background-color: #fff;

  .btn_container {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 500px;
    background-color: #fff;

    .pay_btn {
      color: #fff;
      font-size: 16px;
      height: 108px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 32px 20px;
      background-image: linear-gradient(112deg, #E97A59 0%, #F05844 44%, #E8472C 100%);
      border-radius: 100px;
    }
  }

  .course_item {
    display: flex;
    padding: 15px 15px;
    position: relative;
    cursor: pointer;

    &.active {
      background-color: #f6f6f6;
    }

    .index {
      position: absolute;
      width: 30px;
      text-align: center;
      left: 0;
      top: 0;
      color: rgb(246, 203, 177);
      background-image: linear-gradient(112deg, #141819 0%, #1B2229 44%, #2B313E 100%);
      border-bottom-right-radius: 10px;
      height: 20px;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 5px;
    }

    &:nth-child(2) {
      padding-top: 15px;
    }

    .cover_wrapper {
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      width: 150px;
      height: 100px;
      margin-right: 15px;
      background-size: cover;
      background-position-y: center;
    }

    .body {
      flex: 1;
      //background-color: #e6f8e8;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        width: 310px;
      }

      .tag {
        background-color: #fef0e5;
        color: #ea761e;
        padding: 10px 20px;
        border-radius: 20px;
        margin-right: 15px;
      }

      .tag2 {
        background-color: #fdf8e5;
        color: #f3c00d;
        padding: 10px 20px;
        border-radius: 20px;
        margin-right: 15px;
      }

      .count {
        color: #666;
        font-size: 12px;
      }

      .bottom {
        background-color: #f3f5ff;
        display: flex;
        border-radius: 20px;
        margin-top: 10px;
        height: 40px;
        align-items: center;

        .text1 {
          margin-left: 20px;
          color: #d55a0a;
          margin-right: 20px;
        }

        .text2 {
          color: #1b040a;
          flex: 1;
        }

        .btn {
          background-image: linear-gradient(to right, rgb(42, 42, 42), rgb(25, 26, 31));
          color: rgb(246, 203, 177);
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          font-size: 14px;
          border-radius: 22px;

          &.try {
            background-color: #024eed;
            background-image: none;
            color: #fff;
          }

          &.free {
            background-color: #024eed;
            background-image: none;
            color: #fff;
          }
        }
      }
    }

    .label {
      margin-top: 30px;
      font-size: 15px;
      color: #000;

      &.active {
        color: #0D94FF;
      }
    }

    .vip {
      position: absolute;
      background-image: linear-gradient(112deg, #141819 0%, #1B2229 44%, #2B313E 100%);
      color: #FFE7BB;
      right: 10px;
      top: 10px;
      font-size: 12px;
      padding: 8px 20px;
      border-radius: 10px;
    }
  }
}
</style>
