import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'

import LoginScreen from '@/pages/LoginScreen.vue'
import BasicLayout from '@/pages/layout/BasicLayout.vue'
import Kemu2Screen from '@/pages/mobile/Kemu2Screen.vue'
import Kemu3Screen from '@/pages/mobile/Kemu3Screen.vue'
import Kemu4Screen from '@/pages/mobile/Kemu4Screen.vue'
import MijuanScreen from '@/pages/mobile/MijuanScreen.vue'
import JingjiangScreen from '@/pages/mobile/JingjiangScreen.vue'
import DifangtiScreen from '@/pages/mobile/DifangtiScreen.vue'
import KoujueScreen from '@/pages/mobile/KoujueScreen.vue'
import KaodianScreen from '@/pages/mobile/KaodianScreen.vue'
import KaochangEnterScreen from '@/pages/mobile/KaochangEnterScreen.vue'
import JingxuanScreen from '@/pages/mobile/JingxuanScreen.vue'
import ExamScreen from '@/pages/mobile/ExamScreen.vue'
import LianxiScreen from '@/pages/mobile/LianxiScreen.vue'
import Kemu1Screen from '@/pages/mobile/Kemu1Screen.vue'
import QuestionDetail from '@/pages/QuestionDetail.vue'
import KaochangScreen from '@/pages/mobile/KaochangScreen.vue'
import MineCollect from '@/pages/mobile/MineCollect.vue'
import MineWrong from '@/pages/mobile/MineWrong.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginScreen
  }, {
    path: '/',
    name: 'need_login',
    component: BasicLayout,
    children: [
      {
        path: '',
        redirect: 'kemu1/home'
      },
      {
        path: 'kemu1/home',
        name: 'kemu1_home',
        component: Kemu1Screen
      },
      {
        path: 'kemu1/mijuan',
        name: 'mijuan',
        component: MijuanScreen
      },
      {
        path: 'kemu1/jingjiang',
        name: 'jingjiang',
        component: JingjiangScreen
      },
      {
        path: 'kemu1/kaodian',
        name: 'kaodian',
        component: KaodianScreen
      },
      {
        path: 'kemu1/mine_collect',
        component: MineCollect
      },
      {
        path: 'kemu4/mine_collect',
        component: MineCollect
      },
      {
        path: 'kemu1/mine_wrong',
        component: MineWrong
      },
      {
        path: 'kemu4/mine_wrong',
        component: MineWrong
      },
      {
        path: 'kemu4/kaodian',
        name: 'kaodian4',
        component: KaodianScreen
      },
      {
        path: 'kemu1/difangti',
        name: 'difangti',
        component: DifangtiScreen
      },
      {
        path: 'kemu4/difangti',
        name: 'difangti4',
        component: DifangtiScreen
      },
      {
        path: 'kemu1/kaochang_enter',
        name: 'kaochang_enter',
        component: KaochangEnterScreen
      },
      {
        path: 'kemu4/kaochang_enter',
        name: 'kaochang_enter4',
        component: KaochangEnterScreen
      },
      {
        path: 'kemu1/kaochang',
        name: 'kaochang',
        component: KaochangScreen
      },
      {
        path: 'kemu4/kaochang4',
        name: 'kaochang4',
        component: KaochangScreen
      },
      {
        path: 'kemu2/home',
        name: 'kemu2_home',
        component: Kemu2Screen
      },
      {
        path: 'kemu3/home',
        name: 'kemu3_home',
        component: Kemu3Screen
      },
      {
        path: 'kemu4/home',
        name: 'kemu4_home',
        component: Kemu4Screen
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

async function checkLogin (to) {
  if (to.matched[0] && to.matched[0].name === 'need_login') {
    return await store.dispatch('checkLogined')
  }

  return true
}

function checkPermission (to) {
  // if (to.path.indexOf('/manage') > -1) {
  //   return store.getters.permissions.find(permission => permission.code === to.name)
  // }

  return true
}

router.beforeEach(async (to, from, next) => {
  if (await checkLogin(to)) {
    console.log('login...')
    if (checkPermission(to)) {
      console.log('permission...')

      next()
    } else {
      console.log('not_permission')
      next({
        name: 'not_permission'
      })
    }
  } else {
    next({
      name: 'login',
      query: {
        from: to.fullPath
      }
    })
  }
})
