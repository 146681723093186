<template>
  <el-container id="basic-layout-root">
    <el-container id="basic-layout-body-root" style="flex: 20">
      <el-header>
        <HeaderView></HeaderView>
      </el-header>
      <div style="position: absolute;top: 60px;left: 0;right: 0;bottom: 0">
        <router-view></router-view>
      </div>
    </el-container>
  </el-container>
</template>

<script>
import HeaderView from './HeaderView.vue'

export default {
  name: 'BasicLayout',
  components: {
    HeaderView
  }
}
</script>

<style scoped lang="less">
@lineHeight: 0px;
#basic-layout-root {
  width: 100%;
  height: calc(100% - @lineHeight);
  padding-top: @lineHeight;
  display: flex;
  --el-main-padding: 20px;

  #basic-layout-body-root {
    height: 100%;
    flex: 1;

    > .el-header {
      //background-color: #fff;
      background-color: #001529;
      width: 100%;
      z-index: 2;
      color: #fff;
      box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
      position: fixed;
    }
  }
}
</style>
