import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import 'normalize.css'
import 'vue-json-pretty/lib/styles.css'
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import appConfig from '@/appConfig'

const env = process.env.NODE_ENV
// const env = 'production'

appConfig.api = (env === 'production' ? appConfig.release : appConfig.develop) + '/api/jiakao/'

createApp(App).use(ElementPlus, {
  locale: zhCn
}).use(VueVideoPlayer).use(store).use(router).mount('#app')
