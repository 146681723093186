<template>
  <el-dropdown>
    <div style="margin: 5px;display: flex;align-items: center">
      <el-avatar :src="mine.avatarUrl"></el-avatar>
      <span class="username">{{ mine.name }}</span>
      <el-icon class="el-icon--right">
        <arrow-down/>
      </el-icon>
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item divided @click="toLogout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { ArrowDown } from '@element-plus/icons-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ArrowDown
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['mine'])
  },
  methods: {
    ...mapActions(['logout']),
    toLogout () {
      this.logout()
      this.$router.replace('/login')
    }
  }
}
</script>

<style scoped lang="less">
.el-dropdown {
  cursor: pointer;

  :deep(.el-dropdown--default) {
    align-items: center;
    display: flex;
  }

  .el-icon--right {
    color: #fff;
  }

  .username {
    margin-left: 10px;
    color: #fff;
    word-break: keep-all;
  }

  @media (max-width: 600px) {
    .username {
      display: none;
    }
  }
}
</style>
