<template>
  <div class="pdf_detail" v-if="course">
    <div class="process_row">
      <div class="label">学习
        <div class="red">资料查看</div>
      </div>
      <!--        <div class="btn" @click="toDown">-->
      <!--          <div class="icon down"></div>-->
      <!--          下载文档-->
      <!--        </div>-->
      <!--        <div class="btn" @click="toDown">-->
      <!--          <div class="icon send"></div>-->
      <!--          发送到邮箱-->
      <!--        </div>-->
    </div>
    <div class="pdf_list">
      <div v-for="(content, n) in course.contents" :key="content.id">
        <el-image :src="content.coverUrl" lazy class="img" fit="contain"/>
        <div class="page-footer">{{ n + 1 }} /{{ course.contents.length }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { courseApi } from '@/api'

export default {
  props: ['courseId'],
  data () {
    return {
      course: null
    }
  },
  async created () {
    const res = await courseApi.info(this.courseId)
    this.course = res.data.data
  },
  methods: {}
}
</script>

<style scoped lang="less">

.pdf_detail {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;

  .process_row {
    font-size: 14px;
    display: flex;
    color: #000;
    align-items: center;
    padding: 15px;
    background: #fff;
    box-shadow: 0 5px 5px rgba(92, 92, 92, 0.1);
    z-index: 1;

    .label {
      display: flex;
      flex: 1;
      font-weight: bold;
      font-size: 20px;

      .red {
        color: #FB1D1E;
      }
    }

    .btn {
      background-color: #FEF3D2;
      height: 40px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 30px;
      margin-left: 24px;

      .icon {
        width: 20px;
        height: 18px;
        background-size: cover;
        background-position: center;
        margin-right: 10px;

        &.send {
          background-image: url("http://down.qwdata.vip/app_icon/1701687150239");
        }

        &.down {
          background-image: url("http://down.qwdata.vip/app_icon/1701687156798");
        }
      }
    }
  }

  .btn_container {
    .pay_btn {
      color: #fff;
      font-size: 16px;
      height: 108px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 32px 20px;
      background-image: linear-gradient(112deg, #E97A59 0%, #F05844 44%, #E8472C 100%);
      border-radius: 100px;
    }
  }

  .pdf_list {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    overflow-y: auto;
    width: 900px;
    align-self: center;

    .img {
      border: 1px solid #000;
      min-height: 1000px;
      width: 100%;
      box-sizing: border-box;
    }

    .page-footer {
      text-align: center;
      color: #999;
      margin: 15px 0;
    }
  }

  @media (max-width: 1500px) {
    .pdf_list {
      width: 100%;
      .img {
        min-height: 300px;
      }
    }
  }
}
</style>
