import axios from 'axios'
import appConfig from '../appConfig'
import router from '@/router.js'
import { ElMessageBox } from 'element-plus'

class Http {
  get (url, object = {}) {
    object.method = 'GET'
    object.url = url
    return this.request(object)
  }

  _returnUrl (object) {
    return appConfig.api + object.url
  }

  post (url, object = {}) {
    object.method = 'POST'
    object.url = url

    return this.request(object)
  }

  delete (url, object = {}) {
    object.url = url
    object.method = 'DELETE'
    return this.request(object)
  }

  put (url, object = {}) {
    object.method = 'PUT'
    object.url = url

    return this.request(object)
  }

  request (object) {
    object.url = appConfig.api + object.url
    object.withCredentials = true
    const token = localStorage.getItem(appConfig.authTokenKey)
    object.headers = Object.assign({ [appConfig.authTokenKey]: token }, object.headers, appConfig.httpHeaders)

    if (object.return) {
      return object
    }

    return new Promise((resolve, reject) => {
      console.info('start', object)

      axios(object).then(res => {
        console.log('=============', res.headers)
        localStorage.setItem(appConfig.authTokenKey, res.headers[appConfig.authTokenKey])
        if (res.headers['content-type'].indexOf('application/json') > -1) {
          if (res.data.status === 0) {
            console.info('succ', res)
            resolve(res)
          } else {
            console.error('api error', res)
            res.message = res.data.msg
            if (res.data.status === 503) {
              ElMessageBox.alert(res.data.msg, '请求错误', {
                callback: async (action) => {
                  await router.replace({
                    name: 'login'
                  })
                }
              })
            }

            reject(res)
          }
        } else {
          resolve(res)
        }
      }).catch((res, a) => {
        console.error('http error', res, a)
        reject(res)
      })
    })
  }
}

export default new Http()
